import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot  } from '@angular/router';
import { RequestService, LayoutUtilsService } from '../../shared/services';
import { environment } from '../../../environments/environment';

@Injectable()
export class AuthGuard implements CanActivate {
    constructor(private router: Router, private requestService: RequestService, private layoutUtilsService : LayoutUtilsService) {}
    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        if (localStorage.getItem('currentUser')) {
            // logged in so return true
            let currentUser = JSON.parse(localStorage.getItem('currentUser'));
            if (this.legitUser(currentUser)) {
              return true;
            }
        }
        this.layoutUtilsService.showNotification('You have no access to the site.', 'Dismiss');
        // not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});

        return false;
    }
    private legitUser(user): boolean {
      if (user.hasOwnProperty('_id') && user.hasOwnProperty('token')) {
        return true;
      } else {
        return false;
      }
    }
}
