import { Component, OnInit, Input, Output, EventEmitter, NgZone, OnChanges, ChangeDetectorRef, ViewChild, ElementRef, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Subscription } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { environment } from '../../../../environments/environment';
import { RequestService, LayoutUtilsService, StoreService } from '../../../shared/services';
import { ModalUserViewDialogComponent } from '../custom-user-view-dialog/custom-user-view-dialog.component';

import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ModalIframeDialogComponent } from '../iframe-dialog/iframe-dialog.component';
import { trigger, transition, animate, style, state } from '@angular/animations';
import { DeviceDetectorService } from 'ngx-device-detector';

@Component({
  selector: 'app-topnav',
  templateUrl: './topnav.component.html',
  styleUrls: ['./topnav.component.scss'],
  animations: [
    trigger('slideInOut', [
      state('in', style({
        transform: 'translateY(-100%)',
        display: 'none',
        opacity: 0,
      })),
      state('out', style({
        transform: 'translateY(0%)',
        display: 'initial',
        opacity: 1
      })),
      transition('in => out', animate('500ms ease-in-out')),
      transition('out => in', animate('1000ms ease-in-out'))
      // transition('void => *', [
      //   style({ transform: 'translateY(-100%)', display: 'hidden' }),
      //   animate('3000ms ease-in', style({ transform: 'translateY(0%)' }))
      // ]),
      // transition('* => void', [
      //   animate('4000ms ease-in', style({ transform: 'translateY(-100%)' }))
      // ])
    ])
  ]
})
export class TopnavComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription[] = [];
  public selectedUser: any;
  public lockSite = environment.lockSite;
  public hasListingDirectory = environment.hasListingDirectory;
  public permission: any[] = [environment.customKeys.roleAdmin, environment.customKeys.roleView];
  public viewPermission: any[] = [environment.customKeys.roleView];
  public title = environment.serverName;
  public subTitle = environment.subServerName;
  // public selectedOrganization: string = '';
  // public selectedEntity: string = '';
  public pushRightClass: string;
  public userType: string = 'default';
  public dataType: string = 'organization';
  public dataTypeDisplay: string = 'Organization';
  public selectedDatafilters: any = undefined;
  isMobile: any;

  @Input() showExit: boolean = false;
  constructor(
    private layoutUtilsService: LayoutUtilsService, private requestService: RequestService, public router: Router, private translate: TranslateService,
    private storeService: StoreService,
    public dialog: MatDialog, private zone: NgZone, private route: ActivatedRoute, public changeDetectorRef: ChangeDetectorRef, private deviceService: DeviceDetectorService) {
    // this.router.events.subscribe(val => {
    //     if (val instanceof NavigationEnd && window.innerWidth <= 992 && this.isToggled()) {
    //         this.toggleSidebar();
    //     }
    // });
    this.isMobile = this.deviceService.isMobile();
    this.userType = this.requestService.getUserType();
  }

  ngOnInit() {
    this.pushRightClass = 'push-right';
    this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
          // let orgsList = this.requestService.getListContains(this.selectedUser['organizationId'], '_id');
          // let filter = {'$and': []};
          // filter['$and'].push({'_id': { '$in': orgsList }});
          // this.selectedDatafilters = filter;
          // if(this.selectedUser && this.selectedUser.hasOwnProperty('organizationId') && this.selectedUser['organizationId'].length > 0){
          //   let orgId = JSON.parse(localStorage.getItem('o'));
          //   let org = this.requestService.getItemListContains(this.selectedUser['organizationId'],orgId);
          //   if (org) {
          //     this.setSelectedOrganization(org, false);
          //   }else{
          //     this.setSelectedOrganization(this.selectedUser['organizationId'][0], false);
          //   }
          // }
        }
      })
    );
    // this.subscriptions.push(
    //   this.requestService.pageOrganization.subscribe((data) => {
    //     if (data) {
    //       this.selectedOrganization = data._id;
    //     }else{
    //       this.selectedOrganization = '';
    //     }
    //   })
    // );
    // this.subscriptions.push(
    //   this.requestService.pageEntity.subscribe((data) => {
    //     if (data) {
    //       this.selectedEntity = data._id;
    //       this.requestService.entityId = data._id;
    //       localStorage.setItem('e', JSON.stringify(data._id));
    //       localStorage.setItem('entity', JSON.stringify(data));
    //     }else{
    //       this.selectedEntity = '';
    //       this.requestService.entityId = undefined;
    //       localStorage.removeItem('e');
    //       localStorage.removeItem('entity');
    //     }
    //   })
    // );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
  // setSelectedOrganization(e, reload = true) {
  //   // console.log('setSelectedOrganization', e);
  //   this.requestService.pageOrganization.next(e);
  //   localStorage.setItem('o', JSON.stringify(e._id));
  //   localStorage.setItem('org', JSON.stringify(e));
  //   if(reload){
  //     this.requestService.pageEntity.next(undefined);
  //     setTimeout(() => {
  //         window.location.reload();
  // 		}, 500);
  //   }
  // }
  isToggled(): boolean {
    const dom: Element = document.querySelector('body');
    return dom.classList.contains(this.pushRightClass);
  }

  toggleSidebar() {
    const dom: any = document.querySelector('body');
    dom.classList.toggle(this.pushRightClass);
  }

  goHome() {
    this.router.navigate(['/']);
  }

  onLoggedout() {
    this.requestService.logOutApi();
  }

  changeLang(language: string) {
    this.translate.use(language);
  }
  // public viewProfile(){
  //   const dialogRef = this.dialog.open(ModalUserDialogComponent, {
  //       width: '1200px',
  //       data: {
  //         title: 'My Profile',
  //         data: this.selectedUser,
  //       }
  //     });
  //   dialogRef.afterClosed().subscribe(result => {
  //     if (result) {
  //       console.log('result', result);
  //     }
  //   });
  // }
  viewProfile() {
    const dialogRef = this.dialog.open(ModalUserViewDialogComponent, {
      width: '1000px',
      data: {
        dataType: 'account',
        title: this.translate.instant('My Profile'),
        profile: true,
        data: { _id: this.selectedUser._id },
        modalSetting: undefined
      }
    });
    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        //console.log('result', result);
      }
    });
  }

  public detectChanges() {
    if (!this.changeDetectorRef['destroyed']) {
      this.changeDetectorRef.detectChanges();
    }
  }
}
