import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, PageScopeService } from '../../../shared/services';
import { environment } from '../../../../environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { ModalPasswordDialogComponent } from '../../../shared/components/custom-password-dialog/custom-password-dialog.component';
import { ConfirmEntityDialogComponent } from '../modals/confirm-entity-dialog/confirm-entity-dialog.component';

export interface UserViewDialogData {
	dataType: string;
	title: string;
	data: any;
	profile: boolean;
	modalSetting: any;
	confirmData: any;
}

@Component({
	selector: 'app-user-view-dialog-modal',
	templateUrl: './custom-user-view-dialog.component.html',
	styleUrls: ['./custom-user-view-dialog.component.scss']
})
export class ModalUserViewDialogComponent implements OnInit {
	private subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public scopes: string[] = [];
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
	public pictureLink: string = 'assets/images/profile.png';
	public fieldsDetails: any = undefined;
	public permission: any[] = [];
	public metaFieldTabsSetting = [];
	public tableDataTemp: any = undefined;
	public normalDisplay: string[] = ['string', 'integer', 'phone', 'number', 'email', 'textarea'];

	constructor(
		public dialog: MatDialog, private translate: TranslateService,
		private requestService: RequestService,
		private layoutUtilsService: LayoutUtilsService,
		private pageScopeService: PageScopeService,
		public dialogRef: MatDialogRef<ModalUserViewDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: UserViewDialogData) {
		// console.log('UserViewDialogData', data);
	}

	ngOnInit() {
		this.buildSetting();
		this.subscriptions.push(
			this.pageScopeService.menuSelectedScope.subscribe((scopes) => {
				this.scopes = scopes
			}
			)
		);
	}
	private buildSetting() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let orgId = undefined;
			let lang = undefined;
			this.requestService.getMetaData(this.data.dataType, undefined, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				this.loading = false;
				if (data) {
					this.data.modalSetting = data.results;
					this.fieldsDetails = this.buildMetaSetting(data.results);
					//console.log('this.fieldsDetails', this.fieldsDetails);
					if (this.data.data.hasOwnProperty('_id')) {
						this.loadData();
					}
				} else {
					this.layoutUtilsService.showNotification(this.translate.instant('Something is Wrong'), this.translate.instant('Dismiss'));
				}
			}, orgId, lang);
		}
	}
	public loadData() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			let dataId = this.data.data['_id'];
			this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.tableDataTemp = Object.assign({}, data.results);
					this.data.confirmData = Object.assign({}, data.results);
					// console.log('this.data.confirmData ', this.data.confirmData);
					if (data.results['pictureLink']) {
						this.pictureLink = data.results['pictureLink'];
					}
				}
				this.loading = false;
			});
		}
	}
	private returnObjectOfArray(_id) {
		for (let cmp of this.tableDataTemp['competencies']) {
			if (cmp.benchmarkId._id === _id) {
				return cmp;
			}
		}
		return undefined;
	}
	convertArrayToObject(arr: any[]) {
		let obj = {};
		for (let a of arr) {
			obj[a._id] = a;
		}
		return obj;
	}
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	buildMetaSetting(data, parent = undefined) {
		let dataObject = {};
		let tabObject = [];
		for (let col of data.fields) {
			if ((col.editable || !col.generated) && col.type !== 'object' && col.type !== 'table') {
				if (parent) {
					col['inputName'] = parent + col['name'];
				}
				dataObject[col.name] = col;
			} else if (col.type === 'object') {
				dataObject[col.name] = this.buildMetaSetting(col);
				tabObject.push({ name: col.name, displayName: col.displayName, fields: this.buildMetaSetting(col, col.name) });
			} else if (col.type === 'table') {
				dataObject[col.name] = this.buildMetaSetting(col);
			}
		}
		this.metaFieldTabsSetting = tabObject;
		return dataObject;
	}
	changePassword() {
		const dialogRef = this.dialog.open(ModalPasswordDialogComponent, {
			width: '300px',
			data: {
				title: this.translate.instant('Change Password')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				//console.log('result', result);
			}
		});
	}
	resetPassword() {
		const dialogRef = this.dialog.open(ConfirmEntityDialogComponent, {
			width: '300px',
			data: {
				title: this.translate.instant('Reset Password'),
				description: this.translate.instant('Are you sure you want to reset this user password?'),
				cancelbtn: this.translate.instant('Close'),
				confirmbtn: this.translate.instant('Reset')
			}
		});
		dialogRef.afterClosed().subscribe(result => {
			if (result) {
				//console.log('result', result);
				if (result) {
					this.confirmResetPassword();
				}
			}
		});
	}
	confirmResetPassword() {
		if (!this.loading) {
			this.loading = true;
			this.errorMessage = '';
			this.requestService.resetUserPassword(this.data.data['_id'], (data, error) => {
				if (error) {
					this.errorMessage = error;
					this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
				}
				if (data) {
					this.layoutUtilsService.showNotification(this.translate.instant('Password Reset Confirmed'), this.translate.instant('Dismiss'));
				}
				this.loading = false;
			});
		}
	}
	public buildTabsMetaSetting() {
		if (this.data.confirmData) {
			for (let tab of this.metaFieldTabsSetting) {
				if (!this.tableDataTemp.hasOwnProperty(tab.name)) {
					if (this.data.confirmData.hasOwnProperty(tab.name)) {
						delete this.data.confirmData[tab.name];
					}
				}
				if (this.data.confirmData.hasOwnProperty(tab.name) || this.data.confirmData['role']['targetfield'] === tab.name) {
					if (!this.data.confirmData.hasOwnProperty(tab.name)) {
						this.data.confirmData[tab.name] = {}
					}
				}
			}
		}
	}
	public viewTranscript(transcript: any) {
		window.open(transcript.fileLink, '_blank');
	}
}
