import { LayoutModule } from '@angular/cdk/layout';
import { OverlayModule } from '@angular/cdk/overlay';
import { NgModule, ErrorHandler } from '@angular/core';
// NGX Permissions
//import { NgxPermissionsModule } from 'ngx-permissions';
import { NgxPermissionsModule } from './shared/modules/ngx-permissions';

import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AuthGuard, RequestService, StoreService, LoggerService, GlobalErrorHandler, LoaderService, UserDefaultsService } from './shared';
import { LayoutUtilsService, MenuConfigService, SubheaderService, PageScopeService, UrlHelperService } from './shared/services';
import { SharedModule } from './shared/shared.module';
import { LayoutComponentModule } from './shared/layout.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CKEditorModule } from 'ngx-ckeditor';
import { ImageCropperModule } from 'ngx-image-cropper';
import { EmptyComponent } from './empty/empty.component';
// AoT requires an exported function for factories
export const createTranslateLoader = (http: HttpClient) => {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
};

@NgModule({
    declarations: [AppComponent, EmptyComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        LayoutModule,
        SharedModule,
        LayoutComponentModule,
        OverlayModule,
        HttpClientModule,
        CKEditorModule,
        ImageCropperModule,
        NgxPermissionsModule.forRoot(),
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: createTranslateLoader,
                deps: [HttpClient]
            }
        })
    ],
    providers: [
      AuthGuard,
      RequestService,
      UserDefaultsService,
      LoaderService,
      StoreService,
      LayoutUtilsService,
      MenuConfigService,
      SubheaderService,
      PageScopeService,
      UrlHelperService,
      LoggerService,
      {
        provide: ErrorHandler,
        useClass: GlobalErrorHandler
      }
    ],
    bootstrap: [AppComponent]
})
export class AppModule {}
