import { Component, OnInit, Input, EventEmitter, Output, ViewChild, Renderer2 } from '@angular/core';
import { RequestService, LayoutUtilsService } from '../../../shared/services';
import { Subscription } from 'rxjs';
import * as RecordRTC from 'recordrtc';
declare var navigator;


@Component({
    selector: 'app-custom-screen-capture',
    templateUrl: './custom-screen-capture.component.html',
    styleUrls: ['./custom-screen-capture.component.scss']
})
export class CustomScreenCaptureComponent implements OnInit {
  	private subscriptions: Subscription[] = [];
    public errorMessage: string = '';
    public disabled: boolean = false;
    @ViewChild('video') video;
    private recorder: any;
    public hasDownload: boolean = false;
    @Input() withVideo: boolean = false;
    @Input() withAudio: boolean = false;
    @Input() withPreview: boolean = false;
    @Input() canDownload: boolean = false;
    @Input() videoWidth: string = '360px';
    constructor(private renderer: Renderer2, private layoutUtilsService: LayoutUtilsService) {
    }
    ngOnInit() {
      if(!navigator.getDisplayMedia && !navigator.mediaDevices.getDisplayMedia) {
        this.errorMessage = 'Your browser does NOT support Screen capture.';
      }
    }
    ngOnDestroy() {
     this.triggerStopCaptureScreen();
     this.subscriptions.forEach(el => el.unsubscribe());
    }
    ngAfterViewInit() {
      // set the initial state of the video
      const video: HTMLVideoElement = this.video.nativeElement;
      this.renderer.setStyle(video, 'width', this.videoWidth);
      video.muted = false;
      video.controls = true;
      video.autoplay = true;
    }

    invokeGetDisplayMedia(success, error) {
      let displaymediastreamconstraints: any = {
          video: {
              displaySurface: 'monitor', // monitor, window, application, browser
              logicalSurface: true,
              cursor: 'always' // never, always, motion
          }
      };

      // above constraints are NOT supported YET
      // that's why overriding them
      displaymediastreamconstraints = {
          video: true
      };

      if(navigator.mediaDevices.getDisplayMedia) {
          navigator.mediaDevices.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
      }
      else {
          navigator.getDisplayMedia(displaymediastreamconstraints).then(success).catch(error);
      }
    }

    captureScreen(callback) {
        this.invokeGetDisplayMedia((screen)=>{
          this.addStreamStopListener(screen, () => {
              this.triggerStopCaptureScreen();
          });
          callback(screen);
        }, (error) => {
            console.error(error);
            alert('Unable to capture your screen. Please check console logs.\n' + error);
        });
    }
    captureCamera(cb) {
      navigator.mediaDevices.getUserMedia({audio: this.withAudio, video: this.withVideo}).then(cb);
    }
    keepStreamActive(stream) {
        var video = document.createElement('video');
        video.className = "aDummyVideo";
        video.muted = true;
        video.srcObject = stream;
        video.style.display = 'none';
        (document.body || document.documentElement).appendChild(video);
    }
    triggerCaptureScreenWithUserMedia() {
      if(this.recorder){
        this.recorder.destroy();
      }
      this.recorder = null;
      this.disabled = true;
      this.captureScreen((screen) => {
          this.keepStreamActive(screen);
          this.captureCamera((camera) => {
              this.keepStreamActive(camera);

              screen.width = window.screen.width;
              screen.height = window.screen.height;
              screen.fullcanvas = true;
              if(this.withVideo){
                camera.width = 320;
                camera.height = 240;
                camera.top = screen.height - camera.height;
                camera.left = screen.width - camera.width;
              }
              this.recorder = RecordRTC([screen, camera], {
                  type: 'video',
                  mimeType: 'video/webm;codecs=h264',
                  previewStream: (s) => {
                      const video: HTMLVideoElement = this.video.nativeElement;
                      video.muted = true;
                      video.autoplay = this.withPreview;
                      video.srcObject = s;
                  }
              });

              this.recorder.startRecording();
              this.recorder.screen = screen;
              this.recorder.camera = camera;
          });
      });
    }

    triggerCaptureScreen() {
      this.hasDownload = false;
      this.disabled = true;
      this.captureScreen((screen) => {
          const video: HTMLVideoElement = this.video.nativeElement;
          video.srcObject = screen;

          this.recorder = RecordRTC(screen, {
              type: 'video'
          });

          this.recorder.startRecording();

          // release screen on stopRecording
          this.recorder.screen = screen;
      });
    }
    triggerStopCaptureScreen() {
      this.disabled = false;
      if(this.recorder){
        this.recorder.stopRecording(this.stopRecordingCallback.bind(this));
      }
    }
    stopRecordingCallback(audioVideoWebMURL) {
        const video: HTMLVideoElement = this.video.nativeElement;
        video.src = video.srcObject = null;
        video.src = audioVideoWebMURL;
        video.muted = false;

        if(this.recorder.screen){
          this.recorder.screen.getTracks().forEach((track) => {
              track.stop();
          });
        }
        if(this.recorder.camera){
          this.recorder.camera.getTracks().forEach((track) => {
              track.stop();
          });
        }
        const recordedBlob = this.recorder.getBlob();
        this.recorder.getDataURL((dataURL) => {});
        this.hasDownload = true;
        // this.recorder.destroy();
        // this.recorder = null;

        var elements = (document.body || document.documentElement).querySelectorAll('video.aDummyVideo');
        elements.forEach(e => e.parentNode.removeChild(e));
    }

    addStreamStopListener(stream, callback) {
      stream.addEventListener('ended', () => {
          callback();
          callback = () => {};
      }, false);
      stream.addEventListener('inactive', () => {
          callback();
          callback = () => {};
      }, false);
      stream.getTracks().forEach((track) => {
          track.addEventListener('ended', () => {
              callback();
              callback = () => {};
          }, false);
          track.addEventListener('inactive', () => {
              callback();
              callback = () => {};
          }, false);
      });
  }
  download() {
    this.recorder.save('video.webm');
    // RecordRTC.invokeSaveAsDialog(this.recorder.getBlob(), 'video.webm');
  }

}
