import { Component, Inject, OnInit, ChangeDetectorRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import { TranslateService } from '@ngx-translate/core';
import { FormControl, FormGroupDirective, NgForm, FormGroup } from '@angular/forms';
import { fromEvent, BehaviorSubject, merge, Subscription } from 'rxjs';
import { ErrorStateMatcher } from '@angular/material/core';

export class MyDialogErrorStateMatcher implements ErrorStateMatcher {
    isErrorState(control: FormControl | null, form: FormGroupDirective | NgForm | null): boolean {
        const isSubmitted = form && form.submitted;
        return !!(control && control.invalid && (control.dirty || control.touched || isSubmitted));
    }
}

export interface DialogData {
    dataType: string;
    dataTypeTitle: string;
    title: string;
    variable: string;
    maxHeight: number;
    maxWidth: number;
    targetApi: string;
    targetDeleteApi: string;
    data: any;
    modalSetting: any;
    confirmData: any;
}
@Component({
    selector: 'app-file-upload-dialog',
    templateUrl: './custom-file-upload-dialog.component.html',
    styleUrls: ['./custom-file-upload-dialog.component.scss']
})
export class FileModalDialogComponent implements OnInit {
    public errorMessage: string = '';
    public loading: boolean = false;
    public hasFormErrors: boolean = false;
    public esMatcher = new MyDialogErrorStateMatcher();
    public isSubmitted: boolean = true;
    public allowedExtensions: string[] = ['jpeg', 'jpg', 'bmp', 'png'];
    public pictureLink: string = '';
    public maxHeight: number = 3000;
    public maxWidth: number = 3000;

    constructor(
        public dialogRef: MatDialogRef<FileModalDialogComponent>,
        @Inject(MAT_DIALOG_DATA) public data: DialogData, private loaderService: LoaderService,
        private layoutUtilsService: LayoutUtilsService,
        private requestService: RequestService,
        private changeDetectorRefs: ChangeDetectorRef, private translate: TranslateService) {
        //console.log('DialogData', data);
        this.maxHeight = data.maxHeight || 3000;
        this.maxWidth  = data.maxWidth || 3000;
    }
    ngOnInit() {
        this.loadData();
    }
    public loadData() {
        if (!this.loading) {
            this.loading = true;
            this.errorMessage = '';
            let dataId = this.data.data['_id'];
            this.requestService.getSingleData(this.data.dataType, dataId, (data, error) => {
                if (error) {
                    this.errorMessage = error;
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                }
                if (data) {
                    this.data.data = data.results;
                    this.pictureLink = this.data.data[this.data.variable];
                }
                this.loading = false;
            });
        }
    }
    closeModal(data): void {
        this.dialogRef.close(data);
    }

    /**
    *  @param target: trigger event
    *
    *  trigger read files browsed files
    */
    onBrowseFiles(target: any, type): void {
        this.readFiles(target.files, type);
    }

    /**
     *  @param files: list of browsed files
     *  @param index: iterator over browsed images
     *
     *  read files browsed by user
     */
    readFiles(files, type, index = 0): void {
        let reader = new FileReader();
        if (index in files) {
            let currentFile = { error: false, text: files[index].name.split('.')[0], id: files[index].id, originalFile: files[index], source_url: null };
            let fileExt = files[index].name.split('.').pop();
            const max_size = 5000000;
            const max_height = this.maxHeight;
            const max_width = this.maxWidth;
            if (files[index].size > max_size) {
                this.layoutUtilsService.showNotification(this.translate.instant('Maximum size allowed is') + ' ' + max_size / 1000000 + 'Mb', 'Dismiss');
            } else if (this.allowedExtensions.indexOf(fileExt.toLowerCase()) === -1) {
                currentFile.error = true;
                this.layoutUtilsService.showNotification(this.translate.instant('The file type is not allowed'), 'Dismiss');
            } else {
                this.readFile(files[index], reader, (event) => {
       					      this.loaderService.display(true);
                    var image = new Image();
                    this.readImage(event, image, (imgresult) => {
                        // console.log('readImage', imgresult);
                        var canvas = document.createElement("canvas");
                        var context = canvas.getContext("2d");
                        var ratio = Math.min(max_width / imgresult.width, max_height / imgresult.height);

                        if(imgresult.width < imgresult.width*ratio && imgresult.height < imgresult.height*ratio){
                           canvas.width = imgresult.width;
                           canvas.height= imgresult.height;
                        }else{
                           canvas.width = imgresult.width*ratio;
                           canvas.height= imgresult.height*ratio;
                        }
                        context.drawImage(image,
                            0,
                            0,
                            image.width,
                            image.height,
                            0,
                            0,
                            canvas.width,
                            canvas.height
                        );
                        if (image.width > max_width || image.height > max_height) {
                            currentFile['originalFile'] = this.dataURLtoFile(canvas.toDataURL("image/jpeg", 0.75), currentFile.text + '.jpeg');
                        }
                        this.continueUpload(currentFile);
                    });
                });
            }
        } else {
            this.changeDetectorRefs.detectChanges();
        }
    }
    readFile(file, reader, callback): void {
        reader.onload = () => {
            callback(reader.result);
        }
        reader.readAsDataURL(file);
    }
    readImage(file, image, callback): void {
        image.onload = () => {
            callback(image);
        }
        image.src = file;
    }
    continueUpload(currentFile) {
        this.loaderService.display(true);
        this.requestService.onUploadFilesByPath(this.data.dataType + '/' + this.data.targetApi + '/upload/' + this.data.data._id, currentFile)
            .subscribe(
                (results: any) => {
                    // console.log('currentFile', currentFile);
                   this.loaderService.display(false);
                    if (results['status']) {
                        currentFile.source_url = results.results[this.data.variable];
                        this.pictureLink = results.results[this.data.variable];
                        this.layoutUtilsService.showNotification(this.translate.instant('Image uploaded successfully'), this.translate.instant('Dismiss'));
                    } else {
                        currentFile.error = true;
                        this.layoutUtilsService.showNotification(this.translate.instant('Error:') + results['message'], this.translate.instant('Dismiss'));
                    }
                    this.changeDetectorRefs.detectChanges();
                },
                (error: any) => {
                    //console.log('Error uploading file.', error);
                    this.loaderService.display(false);
                    currentFile.error = true;
                    this.pictureLink = '';
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + ' ' + this.translate.instant('Error uploading file'), this.translate.instant('Dismiss'));
                    this.changeDetectorRefs.detectChanges();
                }
            );
    }
    public deleteFile(targetDeleteApi: string) {
        const _deleteMessage = 'Image ' + this.translate.instant('Cleared Successfully') + '.';
        if (!this.loading) {
            this.loading = true;
            this.errorMessage = '';
            this.requestService.deleteSingleDataByApi(this.data.dataType, this.data.data._id, targetDeleteApi, (data, error) => {
                if (error) {
                    this.errorMessage = error;
                    this.layoutUtilsService.showNotification(this.translate.instant('Error:') + error, this.translate.instant('Dismiss'));
                }
                this.loading = false;
                if (data) {
                    this.layoutUtilsService.showNotification(_deleteMessage, this.translate.instant('Dismiss'));
                    this.loadData();
                }
            });
        }
    }
    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    }
    makeid(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;
        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }
        return result;
    }

}
