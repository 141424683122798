<div class="col-xl-12" >
	<h1 mat-dialog-title *ngIf="data">{{data.title}}</h1>
	<div mat-dialog-content >
		<div class="userInfo">
			<div *ngIf="data && data.data">
				List of visitors that may have been exposed
			</div>
			<mat-form-field >
				<input matInput #searchInput placeholder="{{ 'Search' | translate }}" [value]="searchVal">
			</mat-form-field>
      <mat-card style="max-height: 35vh; overflow: auto;">
				<mat-card-content >
					<mat-list *ngFor="let visit of visitsList">
					 <!-- <mat-list-item>{{visit.tuserid.name}} visited on {{visit.createdAt | date: 'dd-MM-yyyy hh:mm aa' : 'UTC'}}</mat-list-item> -->
					 <mat-list-item>{{visit.tuserid.name}} <span *ngIf="visit.tuserid.email" style="margin-left: 10px;"> ({{visit.tuserid.email}})</span></mat-list-item>
					</mat-list>
					<div class="mat-table__message"><span *ngIf="!loading && tableDataTotal === 0">{{ 'No Vistors Found' | translate }}</span></div>
				</mat-card-content>
      </mat-card>
			<mat-paginator class="paginator" [pageIndex]="pageNumber-1" [hidden]="!pagination" [pageSize]="pageSize" [pageSizeOptions]="[5, 10, 25, 100]" [length]="paginatorTotal | async" [showFirstLastButtons]="true"></mat-paginator>
    </div>
	</div>
	<div mat-dialog-actions>
			<button mat-raised-button color="danger" (click)="closeModal(undefined)">{{'Close' | translate}}</button>
	</div>
	<br/>
</div>
