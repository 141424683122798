<mat-toolbar color="primary" class="fix-nav" *ngIf="selectedUser">
    <ng-template [ngxPermissionsOnly]="permission">
        <button type="button" mat-icon-button class="visible-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>menu</mat-icon>
        </button>
        <button type="button" mat-icon-button class="hidden-sm" (click)="toggleSidebar()" exceptclick>
            <mat-icon aria-label="Side nav toggle icon" exceptclick>vertical_split</mat-icon>
        </button>
    </ng-template>
    <div class="nav-brand" title="{{subTitle}}">
        <!-- {{title}} -->
        <img src="assets/images/top-logo-min.png" class="circle z-depth-2 responsive-img activator"
            alt="{{title}}">
        <!-- <span style="letter-spacing: 2px;" class="hidden-sm">{{title}}</span> -->
    </div>
    <!-- <form class="hidden-sm" style="margin-left: 20px; margin-top: 5px">
        <mat-form-field>
            <input matInput [placeholder]="'Search' | translate">
        </mat-form-field>
    </form> -->
    <!-- <span class="nav-spacer"></span> -->
    <!-- <a href="https://github.com/start-javascript/sb-admin-material/archive/master.zip" class="hidden-sm" mat-raised-button style="margin-right: 10px">
        <mat-icon>cloud_download</mat-icon> {{ 'Download Now' | translate }}
    </a> -->
    <!-- <button class="hidden-sm" mat-icon-button [matMenuTriggerFor]="language">
        <mat-icon>language</mat-icon>
    </button>
    <mat-menu #language="matMenu">
        <button mat-menu-item (click)="changeLang('en')">
            <span>{{ 'English' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('fr')">
            <span>{{ 'French' | translate }}</span>
        </button>
        <button mat-menu-item (click)="changeLang('ur')">
            <span>{{ 'Urdu' | translate }}s</span>
        </button>
    </mat-menu> -->
    <div class="lectureTitle hidden-sm" >
    </div>
    <!-- <div class="nav-org-list" *ngIf="selectedUser['organizationId'].length > 1">
      <app-custom-select-autocomplete [dataType]="dataType" [filters]="selectedDatafilters" #customselectautocomplete [value]="selectedOrganization" [dataTypeDisplay]="dataTypeDisplay" [placeholder]="'Select ' + dataTypeDisplay" [canDelete]="false" (onSelectReturn)="setSelectedOrganization($event)"></app-custom-select-autocomplete>
    </div> -->
    <div style="display: inline-flex;">
        <div class="icon-container">
            <button mat-icon-button [routerLink] (click)="viewProfile()"
                title="{{selectedUser.firstName}} {{selectedUser.lastName}}">
                <!-- <mat-icon>account_circle</mat-icon> -->
                <span style="font-weight: bold">{{selectedUser.firstName | myfirstLetterPipe}}
                    {{selectedUser.lastName | myfirstLetterPipe}}</span>
            </button>
            <div class="icon-tooltip">
                {{'Profile' | translate}}
            </div>
        </div>
        <div class="icon-container" *ngIf="!showExit"
            style="padding-right: 15px;">
            <button mat-icon-button [disableRipple]="true" [routerLink] (click)="goHome()"
                title="{{'Go to' | translate}} {{'Home' | translate}}">
                <mat-icon style="color: #404040; font-size: 30pt;">home</mat-icon>
            </button>
            <div class="icon-tooltip home-icon">
                {{'Home' | translate}}
            </div>
        </div>
        <!-- showExit || (roomData && roomData.main) -->
        <div class="icon-container">
            <button mat-icon-button (click)="onLoggedout()">
                <mat-icon>exit_to_app</mat-icon>
            </button>
            <div class="icon-tooltip">
                {{'Log Out' | translate}}
            </div>
        </div>
    </div>
</mat-toolbar>
