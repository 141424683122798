import { Component, Inject, OnInit, ChangeDetectorRef, ViewChild, ElementRef } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { RequestService, LayoutUtilsService, LoaderService } from '../../../shared/services';
import { urlSafeBase64Encoding } from '../../../shared/helpers';
import * as moment from 'moment';
import { TranslateService } from '@ngx-translate/core';
import { MatPaginator } from '@angular/material/paginator';

import { debounceTime, distinctUntilChanged, tap } from 'rxjs/operators';
import { fromEvent, BehaviorSubject, merge, Subscription, interval } from 'rxjs';

interface ViewDialogData {
	title: string;
	data: any;
	dataType: string;
	confirmData: any;
}

@Component({
	selector: 'app-potential-dialog-modal',
	templateUrl: './custom-potential-dialog.component.html',
	styleUrls: ['./custom-potential-dialog.component.scss']
})
export class ModalPotentialDialogComponent implements OnInit {
  protected subscriptions: Subscription[] = [];
	public errorMessage: string = '';
	public loading: boolean = false;
	public hasFormErrors: boolean = false;
  public selectedUser: any;
  public visitsList: any[] = [];
  displayedColumns = ['tuserid', 'createdAt'];

	pageSize = 10;
  pageNumber = 1;
  orderDir = 'asc';
  orderBy = 'name';
  searchText = '';

	public searchVal: string = '';
  public pagination: boolean = true;
  public tableDataTotal: number = 0;
  public paginatorTotal: BehaviorSubject<number> = new BehaviorSubject<number>(0);
  @ViewChild('searchInput') searchInput: ElementRef;
  @ViewChild(MatPaginator) paginator: MatPaginator;
	constructor(
		private requestService: RequestService, private translate: TranslateService,
		private layoutUtilsService: LayoutUtilsService,
		public dialogRef: MatDialogRef<ModalPotentialDialogComponent>,
		@Inject(MAT_DIALOG_DATA) public data: ViewDialogData) {
		console.log('ViewDialogData', data);
	}
	ngOnDestroy() {
    this.subscriptions.forEach(el => el.unsubscribe());
  }
	ngOnInit() {
		this.subscriptions.push(
      this.requestService.currentUserSubject.subscribe((data) => {
        if (data) {
          this.selectedUser = data;
					this.loadDataSearch();
        }
      })
    );
	}
	ngAfterViewInit() {
    const searchSubscription = fromEvent(this.searchInput.nativeElement, 'keyup').pipe(
      // tslint:disable-next-line:max-line-length
      debounceTime(150), // The user can type quite quickly in the input box, and that could trigger a lot of server requests. With this operator, we are limiting the amount of server requests emitted to a maximum of one every 150ms
      distinctUntilChanged(), // This operator will eliminate duplicate values
      tap(() => {
        this.paginator.pageIndex = 0;
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(searchSubscription);
    const paginatorSubscriptions = merge(this.paginator.page).pipe(
      tap(() => {
        this.getTableVariables();
        this.loadDataSearch();
      })
    )
      .subscribe();
    this.subscriptions.push(paginatorSubscriptions);
  }
	public getTableVariables() {
    this.pageNumber = this.paginator.pageIndex + 1;
    this.pageSize = this.paginator.pageSize;
  }
	public loadDataSearch() {
    if (!this.loading) {
      this.loading = true;
			let termConfiguration = this.termConfiguration();
      this.errorMessage = '';
			// let visitedDate = moment.utc(this.data.data.visitedAt).add(3, 'hours');
      let filterConfiguration:any = { '$and': [
					{ "entity._id": { "$eq": this.data.data._id } },
				 	// { "tuserid._id": { "$ne": this.selectedUser._id } },

				] };

			if(this.data.data.outAt && this.data.data.outAt !== ''){
				filterConfiguration['$and'].push(
					{ '$or': [
						{ "createdAt": { "$gte": this.data.data.visitedAt, "$lt": this.data.data.outAt } },
						{ "outAt": { "$lt": this.data.data.visitedAt, "$gt": this.data.data.outAt } }
					]}
				);
			}	else{
				filterConfiguration['$and'].push(
					{ '$or': [
						{ "createdAt": { "$gte": this.data.data.visitedAt } },
						{ "createdAt": { "$lt": new Date() } }
					]}
				);
			}
      this.requestService.getDataList(this.data.dataType, { page: this.pageNumber, orderDir: this.orderDir, orderBy: this.orderBy, term: termConfiguration, perpage: this.pageSize, filter: filterConfiguration, fieldKeys: ["tuserid", "createdAt"] }, (data, error) => {
        if (error) {
          this.errorMessage = error;
          this.layoutUtilsService.showNotification('Error:' + error, 'Dismiss');
        }
        if (data) {
          this.visitsList = data.results;
          //console.log('dataReturned', dataReturned);

          if (data.pagination) {
            this.tableDataTotal = data.pagination.total;
            this.paginatorTotal.next(data.pagination.total);
          }
				} else {
          this.visitsList = [];
        }
        this.loading = false;
      });
    }
  }
	closeModal(data): void {
		this.dialogRef.close(data);
	}
	/** FILTRATION */
	termConfiguration(): any {
    if(this.searchInput){
      const searchText: string = this.searchInput.nativeElement.value;
      return searchText;
    }
    return '';
  }
}
