export const environment = {
  production: false,
  lockSite: false,
  serverTileUrl: 'https://stellar.staging.ili.ms/',
  serverUrl: 'https://api.staging.ili.ms/attest/',
  serverUrlNoSlash: 'https://api.staging.ili.ms/attest',
  vimeoUrl: 'https://player.vimeo.com/video/',
  serverHostUrl: 'https://attestation.interactivelife.me',
  serverredirectUrl: 'https://www.interactivelife.com/',
  aliveAppIntervalMinutes: 90, // if you keep app inactive
  aliveCheckAppIntervalMinutes: 60, // if you keep app inactive pop a message
  repeatValidateSeconds: 30,
  hasListingDirectory: true,
  identityName: 'Attestation',
  serverName: 'Attestation',
  orgSubType: 'attestation',
  subServerName: 'Attestation',
  profileImageURL: '',
  customKeys: {
    roleAdmin: '5eb137f1d3123a407c7c97cc',// Admin
    roleView: '5ee761c8d3123a0ef0d8cb61',// Viewer
  },
  customKeysView: {
    Admin: 'Admin',
    User: 'View',
  }
};
