import { environment } from '../environments/environment';
export class MenuConfig {
  public defaults: any = {
    aside: {
      self: {},
      items: [
        {
          title: "Dashboard",
          root: true,
          icon: "dashboard",
          page: "/dashboard",
          bullet: "dot"
        },
        {
          title: "My Organizations",
          root: true,
          icon: "business",
          page: "/organizations/list",
          bullet: "dot"
        },
        {
          title: "My Entities",
          root: true,
          icon: "widgets",
          page: "/entities",
          bullet: "dot"
        },
        {
          title: 'Admin',
          root: true,
          bullet: 'dot',
          icon: 'add',
          target: 'admin',
          permission: [environment.customKeys.roleAdmin],
          submenu: [
            {
              title: 'Admin Dashboard',
              page: '/admin/dashboard',
            },
            {
              title: 'Users',
              page: '/admin/users',
            },
            {
              title: 'Organizations',
              page: '/admin/organizations'
            },
            {
              title: 'Entities',
              page: '/admin/entities'
            },
            {
              title: 'Questions',
              page: '/admin/questions'
            }
          ]
        }
      ]
    }
  };

  public get configs(): any {
    return this.defaults;
  }
}
